import styled from "styled-components"
import { Text, Box } from "grommet"
import { colors, responsive } from "../../../theme"

export const Title = styled(Text)`
  font-size: 5rem;
  color: ${p => p.color || 'white'};
  letter-spacing: 1.2px;
  word-break: keep-all;
  font-weight: bold;
  font-family: 'Monda', sans-serif;
  
  ${responsive.xsAndLess} {
    font-size: 3rem;
  }
`;

export const Wrapper = styled(Box)`
   background-color: ${colors.black};
   height: 48%;
   display: flex;
   justify-content: center;
   align-items: center;
   padding-top: 30px;
`;

export const SubTitle = styled(Text)`
  font-size: 1rem;
  color: ${p => p.color || 'white'};
  letter-spacing: 1.2px;
  word-break: keep-all;
  font-family: 'Roboto Slab', 'Cinzel', serif;
  
  ${responsive.xsAndLess} {
    font-size: .7rem;
  }
`;

export const CopyRight = styled.p`
  font-size: .7rem;
  color: white;
  font-family: 'Josefin Sans', sans-serif;
  text-align: right;
  
  ${responsive.xsAndLess} {
    text-align: center;
  }
`;
