import React from 'react'
import { Button, Box as GBox } from 'grommet'
import { Box } from "gestalt"
import { navigate } from "@reach/router"

/*import {
  BigTextX, Title, VerticalText, VerticalLine, TitleBox, Container,
  CenterGrid, LeftGrid, RightGrid, TiltRoot, SubTitle, Canvas
} from './styles'*/
import { Title, SubTitle, Wrapper, CopyRight } from './bottom-fragment.styles'
// import SocialBar from '../../social-bar';

const BottomFragment = () => {

  return (
    <Wrapper>
      <GBox justify="center" align="center">
        <Box paddingY={3}>
          <Title>
            Let's talk!
          </Title>
        </Box>
        <SubTitle>
          I am available for guidance
        </SubTitle>
        <Box paddingY={5}>
          <Button onClick={() => navigate('/contact')} label="CONTACT ME" style={{ borderColor: 'white', color: 'white' }} />
        </Box>
      </GBox>
      <Box paddingY={2}>
        <CopyRight>COPYRIGHT &#9400; {new Date().getFullYear()} REX ISAAC RAPHAEL</CopyRight>
      </Box>
    </Wrapper>
  )
}

export default BottomFragment
