import React from "react"
import { Box as GBox } from "grommet"
import { graphql, useStaticQuery } from "gatsby"
import WorkItem from "../../work-item"
import { List } from "./styles"
import { SubTitle, Title, ResponsivePadding } from "./rw-fragment.styles"
import { colors } from "../../../theme"

const RecentWorksFragment = () => {
  const data = useStaticQuery(graphql`
      query allContentfulPortfolioRecent {
          allContentfulPortfolio(filter: {node_locale: {eq: "en-US"}, featured: {eq: true}}, sort: {fields: title}) {
              edges {
                  node {
                      color
                      createdAt
                      featured
                      id
                      slug
                      title
                      externalLink
                      updatedAt
                      category {
                          title
                      }
                      featuredImage {
                          file {
                              url
                          }
                      }
                  }
              }
          }
      }
  `)

  return (
    <GBox>
      <ResponsivePadding pad={{ top: 'xlarge', bottom: 'medium' }}>
        <GBox pad={{ left: "large" }}>
          <Title color={colors.black}>
            <span>Crafted with love</span>
          </Title>
        </GBox>
        <GBox pad={{ left: "large", top: 'medium' }}>
          <SubTitle color={colors.black}>
            THESE ARE A SELECTIONS OF MY RECENT WORK
          </SubTitle>
        </GBox>
      </ResponsivePadding>
      <List>
        {data.allContentfulPortfolio.edges.map((item, index) => <WorkItem key={index} index={index} data={item.node} />)}
      </List>
    </GBox>
  )
}

export default RecentWorksFragment
