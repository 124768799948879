import React from 'react'
// import Tilt from 'react-tilt'
import { Box as GBox, Stack } from 'grommet'
import { useSpring } from 'react-spring'

import {
  BigTextX, Title, VerticalText, VerticalLine, TitleBox, Container,
  CenterGrid, LeftGrid, RightGrid, TiltRoot, SubTitle, Canvas
} from './styles'
import SocialBar from '../../social-bar';

const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2]
const trans1 = (x, y) => `translate3d(${x / 7}px,${y / 7}px,0)`
const trans3 = (x, y) => `translate3d(${x / 6}px,${y / 6}px,0)`
const trans4 = (x, y) => `translate3d(${x / 6}px,${y / 6}px,0)`

const TopFragment = () => {
  const [props, set] = useSpring(() => ({ xy: [0, 0], config: { mass: 10, tension: 550, friction: 140 } }))

  return (
    <Container height="100vh">
      <LeftGrid>
        <SocialBar vert />
      </LeftGrid>
      <CenterGrid>
        <Canvas>
          <TiltRoot desktopOnly onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}>
            <Stack anchor="center">
              <TitleBox style={{ transform: props.xy.interpolate(trans1) }}>
                <Title style={{ transform: props.xy.interpolate(trans4) }}>REX ISAAC RAPHAEL</Title>
                <SubTitle style={{ transform: props.xy.interpolate(trans3) }}>SENIOR SOFTWARE ENGINEER/ARCHITECT</SubTitle>
              </TitleBox>
              <GBox>
                <BigTextX style={{ transform: props.xy.interpolate(trans1) }}>X</BigTextX>
              </GBox>
            </Stack>
          </TiltRoot>
          <TiltRoot mobileOnly>
              <Stack anchor="center">
                <TitleBox>
                  <Title size="xl" bold="true">REX ISAAC RAPHAEL</Title>
                  <br />
                  <SubTitle>SENIOR SOFTWARE ENGINEER/ARCHITECT</SubTitle>
                </TitleBox>
                <GBox>
                  <BigTextX>X</BigTextX>
                </GBox>
              </Stack>
          </TiltRoot>
        </Canvas>
      </CenterGrid>
      <RightGrid
        align="center"
        justify="end"
        pad={{ horizontal: "medium" }}
        height="100%"
      >
        <GBox align="center" justify="center" style={{ display: 'flex'}}>
          <VerticalText>SCROLL DOWN</VerticalText>
          <VerticalLine />
        </GBox>
      </RightGrid>
    </Container>
  )
}

export default TopFragment
