import styled, { keyframes } from 'styled-components'
import { Text } from 'grommet';
import { lighten } from 'polished';
// import { Link } from 'gatsby';
import { colors, responsive } from '../../theme';

export const Root = styled.a`
`
const moveBgIn = keyframes`
  from {
    background-position: 3000px;
  }

  to {
    background-position: right center;
  }
`;

const growFont = keyframes`
  from {
    font-size: 1rem;
    letter-spacing: 1px;
  }

  to {
    font-size: 1.2rem;
    letter-spacing: 1.2px;
  }
`;
const shrinkFont = keyframes`
  from {
    font-size: 1rem;
    letter-spacing: 1.2px;
  }

  to {
    font-size: 1.2rem;
    letter-spacing: 1px;
  }
`;

const increaseWidth = keyframes`
  from {
    width: 100px;
  }

  to {
    width: 180px;
  }
`;

const decreaseWidth = keyframes`
  from {
    width: 180px;
  }

  to {
    width: 100px;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  
  padding: 90px 0;
  :hover {
    background: ${p => `linear-gradient(to right, ${p.bg} , ${lighten(0.3, p.bg)})`},
    url(${p => p.bgImage});
    
    background-position: right center;
    background-repeat: no-repeat;
    background-size: contain;
    
    animation: ${moveBgIn} 0.4s linear;
    
    #bkNum {
      visibility: hidden;
    }
    
    #trapTitle {
      animation: ${growFont} 0.4s linear;
      font-size: 1.2rem;
      letter-spacing: 1.2px;
    }
    
    #hortz {
      animation: ${increaseWidth} 0.4s linear;
      width: 180px;
      height: 3px;
    }
    
    #viewProject {
      background-color: ${colors.black};
      button {
        color: white;
        border-color: white;
      }
    }
  }
  
  #bigcount {
    color: ${p => lighten(0.4, p.bg)};
    font-size: 7rem;
    font-weight: bold;
    text-align: center;
    line-height: 0;
    font-family: 'Josefin Sans', sans-serif;
    
    ${responsive.xsAndLess} {
      font-size: 5rem;
    }
    
    
    ${responsive.md} {
      font-size: 7rem;
    }
  }
`;

export const Title = styled(Text)`
  font-weight: bold;
  font-family: 'Josefin Sans', sans-serif;
  font-size: 1rem;
  letter-spacing: 1px;
  
  text-transform: uppercase;
  animation: ${shrinkFont} 0.4s linear;
`;

export const SubTitle = styled(Text)`
  font-family: 'Josefin Sans', sans-serif;
  font-size: 0.7rem;
  font-weight: 300;
  
  text-transform: uppercase;
`;

export const BackText = styled.p`
  font-size: .9rem;
  color: ${colors.black};
  font-family: 'Josefin Sans', sans-serif;
  text-align: left;
  font-weight: bold;
`;

export const Hr = styled.hr`
  font-size: .7rem;
  color: ${colors.black};
  font-family: 'Josefin Sans', sans-serif;
  text-align: right;
`;

export const ViewProject = styled.div`
  padding: 20px 30px;
`;

export const HortLine = styled.div`
  min-width: 100px;
  width: auto;
  max-width: 170px;
  align-self: center;

  -webkit-transition: width 2s; /* For Safari 3.1 to 6.0 */
  
  animation: ${decreaseWidth} 0.4s linear;
  
  height: 2px;
  margin-right: 9px;
  
  display: block;
  background-image: -webkit-linear-gradient( rgba(0,0,0,0), rgba(0,0,0,0.75), rgba(0,0,0,0)); 
 background-image:    -moz-linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.75), rgba(0,0,0,0)); 
    background-image:     -ms-linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.75), rgba(0,0,0,0)); 
    background-image:      -o-linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.75), rgba(0,0,0,0)); 
`
