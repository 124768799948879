import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import TopFragment from "../components/fragments/home-fragments/top-fragment";
import BottomFragment from "../components/fragments/home-fragments/bottom-fragment"
import RecentWorksFragment from "../components/fragments/home-fragments/recent-works.fragment"

const IndexPage = (props) => (
  <Layout {...props}>
    <SEO title="Official" />
    <TopFragment />
    <RecentWorksFragment />
    <BottomFragment />
  </Layout>
)

export default IndexPage
