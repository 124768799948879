import styled from "styled-components"
import { Text, Box } from "grommet"
import { colors, responsive } from "../../../theme"

export const Title = styled(Text)`
  font-size: 5rem;
  color: ${p => p.color || 'white'};
  letter-spacing: 1.2px;
  line-height: 5rem;
  word-break: keep-all;
  font-weight: bold;
  font-family: 'Monda', sans-serif;
  
  ${responsive.xsAndLess} {
    font-size: 3rem;
  }
`;

export const Wrapper = styled(Box)`
   background-color: ${colors.black};
   height: 48%;
   display: flex;
   justify-content: center;
   align-items: center;
   padding-top: 30px;
`;

export const SubTitle = styled(Text)`
  font-size: 1rem;
  color: ${p => p.color || 'white'};
  letter-spacing: 1.2px;
  word-break: keep-all;
  font-family: 'Roboto Slab', 'Cinzel', serif;
  
  ${responsive.xsAndLess} {
    font-size: .7rem;
  }
`;

export const HortLine = styled.div`
  min-width: 100px;
  width: auto;
  max-width: 150px;
  align-self: center;
  
  height: 1px;
  margin-right: 9px;
  
  display: block;
  background-image: -webkit-linear-gradient( rgba(0,0,0,0), rgba(0,0,0,0.75), rgba(0,0,0,0)); 
  background-image:    -moz-linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.75), rgba(0,0,0,0)); 
  background-image:     -ms-linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.75), rgba(0,0,0,0)); 
  background-image:      -o-linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.75), rgba(0,0,0,0));
 `

export const ResponsivePadding = styled(Box)`
  ${responsive.lg} {
    padding-left: 80px;
  }
  ${responsive.md} {
    padding-left: 80px;
  }
  ${responsive.smAndLess} {
    padding-left: 50px;
  }
  ${responsive.xsAndLess} {
    padding-left: 2px;
  }
`;