import PropTypes from "prop-types"
import React from "react"
import { Box, Column } from "gestalt"
import { Box as GBox, Button, Stack } from "grommet"

import { Wrapper, HortLine, BackText, Title, SubTitle, Root, ViewProject } from "./styles"

function toStuff(list, separator) {
  const arr = []

  for (let i = 0; i < list.length; i++) {
    if (list[i].hasOwnProperty('title')) {
      arr.push(list[i].title);
    }
  }

  return arr.join(separator || ", ");
}


const WorkItem = ({ data, index }) => {
  const categories = data.category &&  toStuff(data.category)
  return (
    <Root href={data.externalLink} target="_blank">
      <Wrapper
        bg={data.color}
        bgImage={data.featuredImage && data.featuredImage.file.url}
      >
        <Box
          alignItems="center"
          direction="row"
          display="flex"
          color="transparent"
          wrap
          justifyContent="between"
        >
          <Column span={12} mdSpan={6}>
            <GBox direction="row" flex>
              <HortLine id={"hortz"}/>
              <BackText id="bkNum">{"0" + (index + 1)}</BackText>
              <Stack anchor="left" guidingChild="last">
                <GBox pad={{ horizontal: "medium" }}>
                  <p id="bigcount">{"0" + (index + 1)}</p>
                </GBox>
                <GBox pad={{ horizontal: "medium" }}>
                  <Title id="trapTitle">{data.title}</Title>
                  <SubTitle>{categories}</SubTitle>
                </GBox>
              </Stack>
            </GBox>
          </Column>
          <Column span={12} mdSpan={6}>
            <GBox justify="end" align="end">
              <ViewProject id="viewProject">
                <Button color="transparent" label="VIEW PROJECT" primary/>
              </ViewProject>
            </GBox>
          </Column>
        </Box>
      </Wrapper>
    </Root>
  )
}

WorkItem.propTypes = {
  siteTitle: PropTypes.string,
}

WorkItem.defaultProps = {
  siteTitle: ``,
}

export default WorkItem
