import styled from "styled-components"
import is from "styled-is"
import { Box } from "grommet"
import { animated } from 'react-spring'
import { colors, responsive } from "../../../theme"

export const BigTextX = styled(animated.p)`
  @import url('https://fonts.googleapis.com/css?family=Ubuntu:400,500,700&display=swap');
  
  font-size: 30rem;
  color: ${colors.black};
  font-weight: bold;
  padding: 0;
  margin: 0;
  font-family: 'Ubuntu', sans-serif;
  padding-top: -30px;
`

export const TiltRoot = styled(Box)`
  position: relative;
  right: 0;
  ${responsive.xsAndLess} {
    margin-right: -55%;
    right: -80%;
  }
  
  ${is('mobileOnly')`
    ${responsive.xsAndLess} {
      visibility: visible;
    }
    ${responsive.sm} {
      visibility: hidden;
    }
    ${responsive.md} {
      visibility: hidden;
    }
    ${responsive.lg} {
      visibility: hidden;
    }
  `};
  
  ${is('desktopOnly')`
    ${responsive.xsAndLess} {
      visibility: hidden;
    }
    ${responsive.sm} {
      visibility: visible;
    }
    ${responsive.md} {
      visibility: visible;
    }
    ${responsive.lg} {
      visibility: visible;
    }
  `};
`

export const LeftGrid = styled(Box)`
  grid-area: left;
  justify-content: center;
  align-items: center;
  display: flex;
`

export const CenterGrid = styled(Box)`
  grid-area: center;
  justify-content: center;
  align-items: center;
  display: flex;
`

export const RightGrid = styled(Box)`
  grid-area: right;
  justify-content: center;
  align-items: flex-end;
  display: flex;
  margin-right: 0;
  
  ${responsive.xsAndLess} {
    margin-right: 47%;
  }
`

export const Container = styled(Box)`
  padding-top: 90px;
  display: grid;
  grid-template-columns: 0.1fr 1fr 0.1fr; 
  grid-template-rows: auto;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  overflow-x: hidden;
    
  grid-template-areas: 
    "left center right";
    
  ${responsive.xsAndLess} {
    display: grid; 
    grid-template-columns: .3fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr; 
    grid-column-gap: 0px;
    grid-row-gap: 0px; 
      
    grid-template-areas: 
      "center center center"
      "center center center"
      "center center center"
      "center center center"
      "left right right"
      "left right right"
    ;
  }
`

export const Title = styled(animated.p)`
  font-size: 1.2rem;
  color: ${colors.black};
  word-break: keep-all;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  will-change: transform;
  position: absolute;
  top: 0;
  bottom: 0;
`

export const SubTitle = styled(animated.p)`
  font-size: .7rem;
  color: ${colors.black};
  letter-spacing: 0;
  font-weight: 300;
  font-family: 'Poppins', sans-serif;
  will-change: transform;
  position: absolute;
  top: 0;
  margin-top: 40px;
  bottom: 0;
`

export const VerticalText = styled.p`
  font-size: .7rem;
  writing-mode: vertical-rl;
  letter-spacing: 3px;
  text-orientation: mixed;
  font-weight: 200;
  color: ${colors.black};
  font-family: 'Monda', sans-serif;
`

export const VerticalLine = styled.div`
  min-height: 100px;
  height: auto;
  max-height: auto;
  align-self: center;
  width: 1px;
  display: block;
  background-image: -webkit-linear-gradient( rgba(0,0,0,0), rgba(0,0,0,0.75), rgba(0,0,0,0)); 
 background-image:    -moz-linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.75), rgba(0,0,0,0)); 
    background-image:     -ms-linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.75), rgba(0,0,0,0)); 
    background-image:      -o-linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.75), rgba(0,0,0,0)); 
`

export const TitleBox = styled(animated.div)`
  position: absolute;
  width: 300px;
  left: -40%;
  display: block;
  will-change: transform;
`

export const Canvas = styled(animated.div)`
  width: 300px;
  display: block;
`

export const List = styled.ul`
  list-style: none;
  justify-content: center;
  
  padding: 0;
`;
